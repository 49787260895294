jQuery(function($){
  
  //===============================================
  /* トップページのドーナツ画像切り替え */
  //===============================================
  var step = 1;
  setInterval(function(){
    var prev_step = step;
    step++;
    if(step > 6) {
      step = 1;
    }
    $('#donut_svg').addClass('step'+step);
    $('#donut_svg').removeClass('step'+prev_step);
  }, 1000);

  //===============================================
  /* GSAPアニメーション定義 */
  //===============================================
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(MotionPathPlugin);


  //===============================================
  /* GSAP：汎用フェードインアップ（スクロール駆動） */
  //===============================================

  gsap.set(".js-fadeinup", {opacity:0,y: 50}); //初期状態をセット

  ScrollTrigger.batch(".js-fadeinup", {
    onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, stagger: {
      from: "start", //左側から
      amount: 0.2 // 0.8秒おきに
    }}),
    start: "top 80%",
    once: true,
    markers: false
  });


  //===============================================
  /* GSAP：ツバメの動き（スクロール駆動） */
  //===============================================

  gsap.set(".sub_swallow01",{x: 600, y: -200});
  gsap.set(".sub_swallow02",{x: -600, y: 200});
  gsap.set(".sub_swallow03",{x: 600, y: -150});
  gsap.set(".sub_swallow04",{x: -600, y: 200});
  gsap.set(".footer_swallow",{x: 1800, y: -200});

  ScrollTrigger.matchMedia({
    "all": function() {

      gsap.to("#sw01", {
        repeat: 0,
        ease: Power3.easeOut,
        duration: 0.5,
        motionPath: {
          path: "#path01",//pathの取得
          align: "#path01",//path上を移動を可能に
          alignOrigin: [0.5, 0.5],//path上に乗るポイント
          autoRotate: 180,//自動回転
          start: 0,
          end: 1,
        },
        scrollTrigger: {
          trigger: "#swallow01",
          start: "top 60%",
          toggleActions: "play none none none",
          invalidateOnRefresh: true,
        }
      });

      gsap.to("#sw02", {
        repeat: 0,
        ease: Power3.easeOut,//Linearと同じ
        duration: 0.5,
        motionPath: {
          path: "#path02",//pathの取得
        align: "#path02",//path上を移動を可能に
        alignOrigin: [0.5, 0.5],//path上に乗るポイント
        autoRotate: true,//自動回転
        },
        scrollTrigger: {
          trigger: "#swallow02",
          start: "top 60%",
          toggleActions: "play none none none",
          invalidateOnRefresh: true,
        }
      });

      gsap.to("#sw03", {
        repeat: 0,
        ease: Power3.easeOut,//Linearと同じ
        duration: 0.5,
        motionPath: {
          path: "#path03",//pathの取得
          align: "#path03",//path上を移動を可能に
          alignOrigin: [0.5, 0.5],//path上に乗るポイント
          autoRotate: 180,//自動回転
          start: 0,
          end: 1,
        },
        scrollTrigger: {
          trigger: "#swallow03",
          start: "top 60%",
          toggleActions: "play none none none",
          invalidateOnRefresh: true,
        }
      });

      gsap.to("#sw04", {
        repeat: 0,
        ease: Power3.easeOut,//Linearと同じ
        duration: 0.5,
        motionPath: {
          path: "#path04",//pathの取得
          align: "#path04",//path上を移動を可能に
          alignOrigin: [0.5, 0.5],//path上に乗るポイント
          autoRotate: true,//自動回転
        },
        scrollTrigger: {
          trigger: "#swallow04",
          start: "top 60%",
          toggleActions: "play none none none",
          invalidateOnRefresh: true,
        }
      });

      gsap.to(".sub_swallow01", {
        repeat: 0,
        ease: Power4.easeOut,//Linearと同じ
        duration: 1.5,
        x:0,
        y:0,
        scrollTrigger: {
          trigger: ".page_header",
          start: "top 60%",
          toggleActions: "play none none none",
        }
      });

      gsap.to(".sub_swallow02", {
        repeat: 0,
        ease: Power4.easeOut,//Linearと同じ
        duration: 1.5,
        x:0,
        y:0,
        scrollTrigger: {
          trigger: ".sub_swallow02",
          start: "top 90%",
          toggleActions: "play none none none",
        }
      });

      gsap.to(".sub_swallow03", {
        repeat: 0,
        ease: Power4.easeOut,//Linearと同じ
        duration: 1.5,
        x:0,
        y:0,
        scrollTrigger: {
          trigger: ".sub_swallow03",
          start: "top 90%",
          toggleActions: "play none none none",
        }
      });

      gsap.to(".sub_swallow04", {
        repeat: 0,
        ease: Power4.easeOut,//Linearと同じ
        duration: 1.5,
        x:0,
        y:0,
        scrollTrigger: {
          trigger: ".sub_swallow04",
          start: "top 90%",
          toggleActions: "play none none none",
        }
      });

      gsap.to(".footer_swallow", {
        repeat: 0,
        ease: Power4.easeOut,//Linearと同じ
        duration: 1.5,
        x:0,
        y:0,
        scrollTrigger: {
          trigger: "#footer",
          start: "top 60%",
          toggleActions: "play none none none",
        }
      });

    }
  });

  $(window).on('resize', function(){
    ScrollTrigger.refresh();
  });

});